import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Breadcrumb from '../components/Layout/breadcrumb';
import { Col, Row, Container } from 'react-bootstrap';
import { Link, PageProps } from 'gatsby';

import Layout from '../components/Layout/layout';
import Meta from '../components/Layout/head';

import media from '../images/default-social-share.jpg';

export const Head = () => (
    <Meta 
        title={`À propos`} 
        description={`Pourquoi et d'où vient le projet de Rheaparks, voici quelques explications !`}
        lang='fr'
        pathname='/a-propos/'
        media={media}
    >
    </Meta> 
)


const AboutPage = (location: PageProps['location']) => {
    const breadcrumbArray = [['/a-propos/','À propos']]; 

    return (
        <Layout>
            <Container className="pt-3">
                <Row>
                    <Breadcrumb location={location} itemsArray={breadcrumbArray} />
                    <Col className='text-center' lg='6' md='4' sm='12'>
                        <Row>
                            <Col md='12'>
                                <StaticImage 
                                    alt="Clément" 
                                    src="../images/about/clement.jpg" 
                                    placeholder="blurred"
                                    className="rounded"
                                    width={1200}
                                />
                                <p><small>Clément - Développeur iOS</small></p>
                            </Col>
                            <Col md='12'>
                                <StaticImage 
                                    alt="Sandra" 
                                    src="../images/about/sandra.jpg" 
                                    placeholder="blurred"
                                    className="rounded"
                                    width={1200}
                                />
                                <p><small>Sandra - Rédactrice, webmarketer & développeuse web</small></p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='6' md='8' sm='12'>
                    <h1 className='mb-4'>À propos</h1>
                    <p>Hello ! Nous c’est Sandra et Clément, Webmarketer et Développeur mobile dans la vie de tous les jours. 📱</p>
                    <p>À l’âge de 16 ans, nous avons commencé à réaliser quelques randonnées, pour s’évader, changer du quotidien et partir à l’aventure. 
                        Au départ, non-équipés et avec un poids conséquent sur le dos, ces petites expéditions nous ont rapidement donné goût aux voyages, 
                        face à la beauté et au calme de la nature.</p>
                    <p>Le chant des oiseaux, l’observation des animaux, les magnifiques paysages entre montagnes, cascades, lacs et forêts, 
                        ces voyages nous émerveillent sans cesse et nous remplissent toujours autant de joie.</p>
                    <p>Après avoir réalisé l’un de nos plus beaux voyages dans un parc naturel Italien, et au vu des difficultés d’organisation de ces vacances dans ce parc… 
                        Nous est venue l’idée de l’application Rheaparks.</p>
                    <p>Rheaparks a pour but de :</p>
                    <ul>
                        <li>Inspirer à voyager et aimer la nature en découvrant les merveilles naturelles dont regorges la France.</li>
                        <li>Sensibiliser à la protection de la faune et de la flore.</li>
                        <li>Aider à organiser ses vacances pour s’évader, choisir les plus belles randonnées et vivre une expérience unique.</li>
                    </ul>
                    <p>Si vous avez un compte Instagram avec des photos de randonnées, ou si vous travaillez pour un parc naturel / un office de tourisme, n’hésitez pas à nous contacter. 
                        Nous échangerons ensemble et mettrons en avant vos photos ou territoires avec plaisir.</p>
                    <p><b>Merci à tous ceux qui utilisent Rheaparks et qui soutiennent le projet !</b></p>
                    <p><Link to='/contact/'>→ Nous contacter</Link></p>
                    </Col>
                </Row>

            </Container>
        </Layout>
    )
}

export default AboutPage